import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import fetch from 'cross-fetch';

import { ApiDefaultResponseBody, GenericApiResponse } from 'types/ApiResponse';
import { FeatureGroup } from 'types/featureGroup';
import { PoWChallenge, PoWChallengeResponse } from 'types/powChallenge';
import { LocalizedPrice, Price } from 'types/prices';
import { UserWithToken } from 'types/user';

import {
  NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_CMS_API_URL,
  USER_ACCESS_TOKEN_KEY,
  getAuthorizationHeader,
  uploadObjectToS3,
} from './api/utils';
import Logger from './utils/logger';

export const USER_TEST_TOKEN = 'USER_TEST_TOKEN'; // This token can be used in test environment only to authenticate with the mock user 'Alice'

// fetch that adds `Authorization` header
// can be used for both API and CMS API
// if `token` is not provided tries to grab it from the cookie(only usable when called from client side)
export const authFetch = (input: RequestInfo, init?: any, token?: string) => {
  const headers = init?.headers || {};
  Logger.debug('[authFetch]', input);
  return fetch(input, {
    ...init,
    headers: {
      ...headers,
      ...getAuthorizationHeader(token).headers,
    },
  });
};

// CMS API Requests

export const login = async (
  email: string,
  password: string,
  powChallenge: PoWChallenge
): Promise<UserWithToken | ApiDefaultResponseBody> => {
  const res = await fetch(`${NEXT_PUBLIC_CMS_API_URL}/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-challenge-nonce': powChallenge?.nonce ?? '',
      'x-challenge-solution': powChallenge?.solution?.toString() ?? '',
    },
    body: JSON.stringify({ email, password }),
  });
  return res.json();
};

export const generatePowChallenge = async (): Promise<PoWChallengeResponse> => {
  const res = await fetch(`${NEXT_PUBLIC_CMS_API_URL}/api/auth/challenges`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.json();
};

export const getMe = async (token: string): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/users/me`,
    null,
    token
  );
  return res.json();
};

export const getGroups = async (category: string): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/all?category=${category}`
  );
  return res.json();
};

export const getGroup = async (id: number): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/find?id=${id}`
  );
  return res.json();
};

export const putGroup = async ({
  id,
  name,
  invertInDarkMode,
  isFeatured,
}: {
  id: number;
  name?: string;
  invertInDarkMode?: boolean;
  isFeatured?: boolean;
}): Promise<any> => {
  const data = { id, name, invertInDarkMode, isFeatured };

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/groups/update`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const moveGroup = async ({
  id,
  category,
  force,
}: {
  id: number;
  category: string;
  force?: boolean;
}): Promise<any> => {
  const data = { category, force: force ? 'true' : 'false' };

  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/${id}/move`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const deleteGroup = async ({ id }: { id: number }): Promise<any> => {
  const data = { id };

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/groups/delete`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const addTags = async ({
  tags,
  category,
  name,
  filterTags,
  premium,
  published,
}: {
  tags: string[];
  category: string;
  name: string;
  filterTags?: string[];
  premium: string;
  published: string;
}): Promise<any> => {
  const data = { tags, category, name, filterTags, premium, published };
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/addTags`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const removeTags = async ({
  tags,
  category,
  name,
  filterTags,
  premium,
  published,
}: {
  tags: string[];
  category: string;
  name: string;
  filterTags?: string[];
  premium: string;
  published: string;
}): Promise<any> => {
  const data = { tags, category, name, filterTags, premium, published };
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/removeTags`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const addGenerationTags = async ({
  tags,
  category,
  name,
  filterTags,
  premium,
  published,
}: {
  tags: string[];
  category: string;
  name: string;
  filterTags?: string[];
  premium: string;
  published: string;
}): Promise<any> => {
  const data = { tags, category, name, filterTags, premium, published };
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/addGenerationTags`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const removeGenerationTags = async ({
  tags,
  category,
  name,
  filterTags,
  premium,
  published,
}: {
  tags: string[];
  category: string;
  name: string;
  filterTags?: string[];
  premium: string;
  published: string;
}): Promise<any> => {
  const data = { tags, category, name, filterTags, premium, published };
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/removeGenerationTags`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const addTagsToGroup = async ({
  id,
  tags,
  category,
  filterName,
  filterTags,
}: {
  id: number;
  tags: string[];
  category: string;
  filterName?: string;
  filterTags?: string[];
}): Promise<any> => {
  const data = { id, tags, category, filterName, filterTags };
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/groups/addTags`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const removeTagsFromGroup = async ({
  id,
  tags,
  category,
  filterName,
  filterTags,
}: {
  id: number;
  tags: string[];
  category: string;
  filterName?: string;
  filterTags?: string[];
}): Promise<any> => {
  const data = { id, tags, category, filterName, filterTags };
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/removeTags`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const addGenerationTagsToGroup = async ({
  id,
  generationTags,
  category,
  filterName,
  filterTags,
}: {
  id: number;
  generationTags: string[];
  category: string;
  filterName?: string;
  filterTags?: string[];
}): Promise<any> => {
  const data = { id, generationTags, category, filterName, filterTags };

  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/addGenerationTags`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const removeGenerationTagsFromGroup = async ({
  id,
  generationTags,
  category,
  filterName,
  filterTags,
}: {
  id: number;
  generationTags: string[];
  category: string;
  filterName?: string;
  filterTags?: string[];
}): Promise<any> => {
  const data = { id, generationTags, category, filterName, filterTags };
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/groups/removeGenerationTags`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const getTags = async (category: string): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/tags?category=${category}`
  );
  return res.json();
};

export const getGenerationTags = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/generationTags`);
  return res.json();
};

export const getFonts = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/fonts/all`);
  return res.json();
};

export const getFont = async (id: number): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/fonts/find?id=${id}`
  );
  const font = res.json();
  return font;
};

export const getFontTags = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/fonts/tags`);
  return res.json();
};

export const getFontGenerationTags = async (): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/fonts/generationTags`
  );
  return res.json();
};

export const getFontStyleGenerationTags = async (): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/fonts/styles/generationTags`
  );
  return res.json();
};

type ListPriceType = GenericApiResponse<Price[]> & {
  total: number;
  take: number;
  skip: number;
};

export const listPrices = async (
  take: number = 10,
  skip: number = 0
): Promise<ListPriceType> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/list?take=${take}&skip=${skip}`
  );
  return res.json();
};

export const getPrice = async (
  id: string
): Promise<GenericApiResponse<Price>> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prices/get/${id}`);
  return res.json();
};

export const getLocalizedPrice = async (
  priceId: string,
  localizedPriceId: string
): Promise<GenericApiResponse<LocalizedPrice>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/${priceId}/localized-prices/${localizedPriceId}`
  );
  return res.json();
};

export const deletePrice = async (id: string): Promise<GenericApiResponse> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prices/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
  return res.json();
};

export const deleteLocalizedPrice = async (
  priceId: string,
  localizedPriceId: string
): Promise<GenericApiResponse> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/${priceId}/localized-prices/delete`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: localizedPriceId }),
    }
  );
  return res.json();
};

export const updateLocalizedPrice = async (
  priceId: string,
  localizedPrice: any
): Promise<GenericApiResponse<LocalizedPrice>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/${priceId}/localized-prices/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(localizedPrice),
    }
  );
  return res.json();
};

export const createPrice = async (
  price: any
): Promise<GenericApiResponse<Price>> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prices/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(price),
  });
  return res.json();
};

export const createLocalizedPrice = async (
  priceId: string,
  localizedPrice: any
): Promise<GenericApiResponse<LocalizedPrice>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/${priceId}/localized-prices/create`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(localizedPrice),
    }
  );
  return res.json();
};

type ListLocalizedPriceType = GenericApiResponse<LocalizedPrice[]> & {
  total: number;
  take: number;
  skip: number;
};

export const listLocalizedPrices = async (
  id: string,
  take: number = 10,
  skip: number = 0
): Promise<ListLocalizedPriceType> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prices/${id}/localized-prices/list?take=${take}&skip=${skip}`
  );
  return res.json();
};

export const postFont = async ({
  name,
  variable,
  pro,
  files,
  type,
  tags,
  previewFile,
}: {
  name: string;
  files: (File & { style: string })[];
  variable: boolean;
  pro: boolean;
  type: string;
  tags: string[];
  previewFile: File;
}): Promise<any> => {
  const body = new FormData();
  files.forEach((file) => {
    body.append(file.style, file);
  });
  body.append('type', type);
  body.append('tags', JSON.stringify(tags));
  body.append('name', name);
  body.append('previewFile', previewFile);
  body.append('variable', variable.toString());
  body.append('pro', pro.toString());

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/fonts/post`, {
    method: 'POST',
    body,
  });
  if (res.ok) {
    return res.json();
  } else {
    throw new Error('upload error');
  }
};

export const putFont = async ({
  id,
  name,
  variable,
  pro,
  files,
  type,
  tags,
  generationTags,
  styleGenerationTags,
  previewFile,
  previewFileName,
  uploadedFiles,
}: {
  id: number;
  name: string;
  files: (File & { style: string })[];
  variable: boolean;
  pro: boolean;
  type: string;
  tags: string[];
  generationTags: string[];
  styleGenerationTags: { style: string; tags: [] }[];
  previewFile?: File;
  previewFileName?: string;
  uploadedFiles: any[];
}): Promise<any> => {
  const body = new FormData();
  files.forEach((file) => {
    body.append(file.style, file);
  });
  body.append('id', `${id}`);
  body.append('name', name);
  body.append('type', type);
  body.append('tags', JSON.stringify(tags));
  body.append('generationTags', JSON.stringify(generationTags));
  body.append('styleGenerationTags', JSON.stringify(styleGenerationTags));
  previewFile && body.append('previewFile', previewFile);
  previewFileName && body.append('previewFileName', previewFileName);
  body.append('variable', variable.toString());
  body.append('pro', pro.toString());
  body.append('uploadedFiles', JSON.stringify(uploadedFiles));

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/fonts/${id}`, {
    method: 'PUT',
    body,
  });
  return res.json();
};

export const putFontStyleGenerationTags = async ({
  styles,
}: {
  styles: { id: number; tags: string[] }[];
}): Promise<any> => {
  const body = new FormData();
  body.append('styles', JSON.stringify(styles));
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/fonts/styles/generationTags`,
    {
      method: 'PUT',
      body,
    }
  );
  return res.json();
};

export const getOrigins = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/origins/all`);
  const { origins } = await res.json();
  return origins;
};

export const postOrigin = async ({ name }: { name: string }): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/origins/post`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });
  return res.json();
};

export const deleteOrigin = async ({ id }: { id: number }): Promise<any> => {
  const data = { id };

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/origins/delete`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const getMockupTags = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/mockups/tags`);
  const tags = await res.json();
  return tags;
};

export const getUsers = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/users/all`);
  const { users } = await res.json();
  return users;
};

export const postUser = async ({
  name,
  email,
  location,
  description,
  profileImage,
}: {
  name: string;
  email: string;
  location?: string;
  description?: string;
  profileImage: File;
}): Promise<any> => {
  const body = new FormData();
  body.append('name', name);
  body.append('email', email);
  if (location) {
    body.append('location', location);
  }
  if (description) {
    body.append('description', description);
  }
  body.append('file', profileImage);

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/users/post`, {
    method: 'POST',
    body,
  });
  if (res.ok) {
    return res.json();
  } else {
    throw new Error('user creation error');
  }
};

export const putUser = async ({
  id,
  name,
  email,
  location,
  description,
  profileImage,
}: {
  id: number;
  name: string;
  email: string;
  location?: string;
  description?: string;
  profileImage: File;
}): Promise<any> => {
  const body = new FormData();
  body.append('name', name);
  body.append('email', email);
  if (location) {
    body.append('location', location);
  }
  if (description) {
    body.append('description', description);
  }
  body.append('file', profileImage);

  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/users/${id}`, {
    method: 'PUT',
    body,
  });
  if (res.ok) {
    return res.json();
  } else {
    throw new Error('user update error');
  }
};

export const getLayout = async ({ id }: { id: number }): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/layouts/${id}`);
  return res.json();
};

export const getMockup = async ({ id }: { id: number }): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/mockups/${id}`);
  return res.json();
};

export const deleteLayout = async ({ id }: { id: number }): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/layouts/${id}`, {
    method: 'DELETE',
  });
  return res.json();
};

export const deleteMockup = async ({ id }: { id: number }): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/mockups/${id}`, {
    method: 'DELETE',
  });
  return res.json();
};

export const getLayoutGroup = async ({
  groupId,
}: {
  groupId: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/layouts/groups/${groupId}`
  );
  return res.json();
};

export const getMockupGroup = async ({
  groupId,
}: {
  groupId: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/mockups/groups/${groupId}/?all=t`
  );
  return res.json();
};

export const putLayout = async ({
  id,
  groups,
  published,
  name,
  createdByUserId,
  premium,
}: {
  id: number;
  groups: string[];
  published: boolean;
  name: string;
  createdByUserId: number;
  premium: boolean;
}): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/layouts/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      groups,
      published,
      premium,
      createdByUserId,
    }),
  });
  return res.json();
};

export const putMockup = async ({
  id,
  groups,
  tags,
  published,
  name,
  createdByUserId,
  premium,
}: {
  id: number;
  groups: string[];
  tags: string[];
  published: boolean;
  name: string;
  createdByUserId: number;
  premium: boolean;
}): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_CMS_API_URL}/api/mockups/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      groups,
      tags,
      published,
      createdByUserId,
      premium,
    }),
  });
  return res.json();
};

export const getLayouts = async ({
  groupId,
}: {
  groupId: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/layouts/find?groupId=${groupId}`
  );
  return res.json();
};

export const getMockups = async ({
  groupId,
  tags,
}: {
  groupId: number;
  tags?: string[];
}): Promise<any> => {
  const tagsJoined = tags?.join(',');
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/mockups/find?groupId=${groupId}&tags=${tagsJoined}`
  );
  return res.json();
};

export const deleteLayoutGroup = async ({
  groupId,
}: {
  groupId: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/layouts/groups/${groupId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return res.json();
};

export const deleteMockupGroup = async ({
  groupId,
}: {
  groupId: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/mockups/groups/${groupId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return res.json();
};

export const putLayoutGroup = async ({
  id,
  name,
  invertInDarkMode,
}: {
  id: number;
  name?: string;
  invertInDarkMode?: boolean;
}): Promise<any> => {
  const data = { id, name, invertInDarkMode };

  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/layouts/groups/${id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const putMockupGroup = async ({
  id,
  name,
}: {
  id: number;
  name: string;
}): Promise<any> => {
  const data = { id, name };

  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/mockups/groups/${id}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const getLayoutGroups = async (): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/layouts/overview`
  );
  return res.json();
};

export const getMockupGroups = async (): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/mockups/overview/?all=t`
  );
  return res.json();
};

export const postInviteList = async (
  name: string,
  file: File,
  plan: string,
  planTime: string
): Promise<any> => {
  const body = new FormData();
  body.append('file', file);
  body.append('name', name);
  body.append('plan', plan);
  body.append('planTime', planTime);

  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/invites/createlist`, {
    method: 'POST',
    body,
  });
  if (res.ok) {
    return res.json();
  } else {
    Logger.info(res.status, res);
    throw new Error('upload error');
  }
};

export const getPromoCodes = async (): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promocodes/index`, {
    method: 'GET',
  });

  return res.json();
};

export const createPromoCode = async (
  name: string,
  description: string,
  plan: string,
  time: number
): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promocodes/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, description, plan, time }),
  });

  return res.json();
};

export const updatePromoCode = async (
  name: string,
  active: boolean
): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promocodes/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, active: active ? 'true' : 'false' }),
  });

  return res.json();
};

export async function getUserInfo(
  slug: string,
  cookies?: NextApiRequestCookies
): Promise<any> {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;

  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/users/admin/${slug}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
    token
  );

  return res.json();
}

export async function updateUserRole(
  id: string,
  {
    admin,
    creator,
    revenueShare,
    contentManager,
    support,
    reviewer,
    contentCreator,
    mockupManager,
    aiManager,
    challengeManager,
  }: {
    admin?: string;
    creator?: string;
    revenueShare?: string;
    contentManager?: string;
    support?: string;
    reviewer?: string;
    contentCreator?: string;
    mockupManager?: string;
    aiManager?: string;
    challengeManager?: string;
  }
): Promise<any> {
  const body = { userId: id } as any;
  if (admin) {
    body.admin = admin;
  }
  if (creator) {
    body.creator = creator;
  }
  if (revenueShare) {
    body.revenueShare = revenueShare;
  }
  if (contentManager) {
    body.contentManager = contentManager;
  }
  if (support) {
    body.support = support;
  }
  if (reviewer) {
    body.reviewer = reviewer;
  }
  if (contentCreator) {
    body.contentCreator = contentCreator;
  }
  if (mockupManager) {
    body.mockupManager = mockupManager;
  }
  if (aiManager) {
    body.aiManager = aiManager;
  }
  if (challengeManager) {
    body.challengeManager = challengeManager;
  }

  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/users/admin/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return res.json();
}

export async function issueCredit(
  userId: string,
  amount: number,
  comment: string
): Promise<any> {
  const body = { userId, amount, comment } as any;
  const url = `${NEXT_PUBLIC_API_URL}/users/admin/credits/add`;
  const res = await authFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return res.json();
}

export async function issueCredits(
  userId: string,
  amount: number,
  comment: string
): Promise<any> {
  const body = { userId, amount, comment };
  const url = `${NEXT_PUBLIC_API_URL}/users/admin/credits/add`;
  const res = await authFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return res.json();
}

export async function voidCredit(id: string): Promise<any> {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/users/admin/credits/void`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }
  );
  return res.json();
}

export async function updateUserSubscription(
  id: string,
  plan: string,
  days: number
): Promise<any> {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/subscription/admin/change`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, plan, days }),
    }
  );

  return res.json();
}

// we set take to 100, to get all challenges
export const getChallenges = async (
  take = 100,
  cookies?: NextApiRequestCookies,
  filterOption = 'all'
): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : null;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/challenges/index?filter=${filterOption}&take=${take}`,
    {
      method: 'GET',
    },
    token
  );

  return res.json();
};

export const getChallenge = async (
  slug: string,
  cookies?: NextApiRequestCookies
): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/challenges/${slug}`,
    {
      method: 'GET',
    },
    token
  );

  return res.json();
};

export const createChallenge = async (data: any): Promise<any> => {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    value && body.append(key, value as string | Blob);
  });

  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/challenges/create`, {
    method: 'POST',
    body,
  });

  return res.json();
};

export const updateChallenge = async (data: any): Promise<any> => {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    value && body.append(key, value as string | Blob);
  });

  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/challenges/update`, {
    method: 'POST',
    body,
  });

  return res.json();
};

export const getPromptSceneGenerators = async (
  cookies?: NextApiRequestCookies
): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/promptscenes/generators/index`,
    {
      method: 'GET',
    },
    token
  );
  return res.json();
};

export const getPromptSceneGroups = async (
  cookies?: NextApiRequestCookies
): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/promptscenes/groups/index`,
    {
      method: 'GET',
    },
    token
  );
  return res.json();
};

export const getPromptSceneGroup = async (
  id: number,
  cookies?: NextApiRequestCookies
): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/promptscenes/groups/${id}?take=100`,
    {
      method: 'GET',
    },
    token
  );
  return res.json();
};

export const updatePromptScene = async (data: any): Promise<any> => {
  if (data.previewFile) {
    const objectId = await uploadObjectToS3(data.previewFile);
    data.preview = objectId;
  }

  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promptscenes/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const deletePromptScene = async (data: any): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promptscenes/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const updatePromptSceneGroup = async (data: any): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/promptscenes/groups/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const deletePromptSceneGroup = async (data: any): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/promptscenes/groups/delete`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const createPromptScene = async ({
  name,
  prompt,
  negativePrompt,
  regenPrompt,
  generator,
  groups,
  previewFile,
}: {
  name: string;
  prompt: string;
  negativePrompt: string;
  regenPrompt: boolean;
  generator: string;
  groups: string[];
  previewFile: File;
}): Promise<any> => {
  const objectId = await uploadObjectToS3(previewFile);
  const data = {
    name,
    prompt,
    negativePrompt,
    regenPrompt,
    generator,
    groups,
    preview: objectId,
  };
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/promptscenes/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  } else {
    const resJson = await res.json();
    throw new Error(
      `promptscene creation error, API error: ${resJson.error}, API errorMessage: ${resJson.errorMessage}`
    );
  }
};

export const getPromptVariables = async ({
  take,
  skip,
  templateId,
  cookies,
}: {
  take: number;
  skip: number;
  templateId?: number;
  cookies?: NextApiRequestCookies;
}): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/variables/index?take=${take}&skip=${skip}${
      templateId ? `&templateId=${templateId}` : ''
    }`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
    token
  );
  return res.json();
};

export const getPromptVariable = async ({
  id,
}: {
  id: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/variables/${id}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return res.json();
};

export const updatePromptVariable = async (data: any): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/variables/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const updatePromptValue = async (data: any): Promise<any> => {
  if (data.icon) {
    const objectId = await uploadObjectToS3(data.icon);
    data.icon = objectId;
  }

  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/values/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const createPromptValue = async (data: any): Promise<any> => {
  if (data.icon) {
    const objectId = await uploadObjectToS3(data.icon);
    data.icon = objectId;
  }

  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/values/create`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const createPromptVariable = async (data: any): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/variables/create`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  return res.json();
};

export const getPromptTemplates = async ({
  take,
  skip,
  cookies,
}: {
  take: number;
  skip: number;
  cookies?: NextApiRequestCookies;
}): Promise<any> => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/index?take=${take}&skip=${skip}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
    token
  );
  return res.json();
};

export const getPromptTemplateGenerators = async ({
  cookies,
}: {
  cookies?: NextApiRequestCookies;
}) => {
  const token = cookies ? cookies[USER_ACCESS_TOKEN_KEY] : undefined;
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/generators/index`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
    token
  );
  return res.json();
};

export const updatePromptTemplate = async (data: any): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prompttemplates/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const getPromptTemplate = async ({
  id,
}: {
  id: number;
}): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prompttemplates/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  return res.json();
};

export const createPromptTemplate = async (data: any): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prompttemplates/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return res.json();
};

export const deletePromptTemplate = async ({
  id,
}: {
  id: number;
}): Promise<any> => {
  const res = await authFetch(`${NEXT_PUBLIC_API_URL}/prompttemplates/delete`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id }),
  });
  return res.json();
};

export const deletePromptVariable = async ({
  id,
}: {
  id: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/variables/delete`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }
  );
  return res.json();
};

export const deletePromptValue = async ({
  id,
}: {
  id: number;
}): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/prompttemplates/values/delete`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    }
  );
  return res.json();
};

export const sessionImpersonation = async (
  impersonatedUserSlug: string
): Promise<any> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_CMS_API_URL}/api/sessions/users/${impersonatedUserSlug}/impersonate`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return res.json();
};

export const getFeatureGroup = async (
  name: string
): Promise<GenericApiResponse<FeatureGroup>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/feature-group/admin/${name}`,
    {
      method: 'GET',
    }
  );

  return res.json();
};

export const findFeatureGroups = async (params?: {
  name?: string;
  enabled?: boolean;
}): Promise<GenericApiResponse<FeatureGroup[]>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/feature-group/admin/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    }
  );

  return res.json();
};

export const createFeatureGroup = async (params: {
  name: string;
  description?: string;
  assignFlag?: string;
  enabled?: boolean;
  metadata?: Record<string, any>;
}): Promise<GenericApiResponse<FeatureGroup>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/feature-group/admin/create`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    }
  );

  return res.json();
};

export const updateFeatureGroup = async (
  name: string,
  params: {
    description?: string;
    enabled?: boolean;
    assignFlag?: string;
    metadata?: string;
  }
): Promise<GenericApiResponse<FeatureGroup>> => {
  const res = await authFetch(
    `${NEXT_PUBLIC_API_URL}/feature-group/admin/${name}/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    }
  );

  return res.json();
};
