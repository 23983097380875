/* eslint no-console: "off" */
/**
 *
 * Logger
 * Utility class for logging
 *
 */

import axios from 'axios';
const RESET = '\x1b[0m';
const CYAN = '\x1b[36m';
const RED = '\x1b[31m';
const YELLOW = '\x1b[43m';

class Logger {
  public static debug(...args: any[]): void {
    if (process.env.NODE_ENV === 'development' || process.env.DEBUG) {
      console.debug(`[DEBUG]`, ...args);
    }
  }
  public static info(...args: any[]): void {
    if (process.env.NODE_ENV !== 'test') {
      console.info(`${CYAN}[INFO]${RESET}`, ...args);
    }
  }
  public static warn(...args: any[]): void {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`${YELLOW}[WARN]${RESET}`, ...args);
    }
  }
  public static error(...args: any[]): void {
    console.error(`${RED}[ERROR]${RESET}`, ...args);
  }
  private static async sendSlackMessage(
    channel: string,
    text: string
  ): Promise<void> {
    if (process.env.SLACK_WEBHOOK_URL) {
      const payload = {
        username: 'Bob Ross',
        text,
        icon_emoji: ':bob_ross:',
        channel,
      };
      try {
        await axios.post(process.env.SLACK_WEBHOOK_URL, payload);
      } catch (e) {
        console.error(e);
      }
    }
  }
  public static slackWarn(channel: string, ...args: any[]): void {
    this.sendSlackMessage(channel, args.join(' '));
    Logger.warn(...args);
  }
}

export default Logger;
